export const roles = [
  {title: 'Onbekend', id: 0},
  {title: 'Lid', id: 1},
  {title: 'Medewerker', id: 2},
  {title: 'Redacteur', id: 3},
  {title: 'Beheerder', id: 4},
  {title: 'Developer', id: 5},
];

export const rolesSelect = [
  {title: 'Geen', id: 0},
  {title: 'Kijker', id: 1},
  {title: 'Materiaalbeheerder', id: 2},
  {title: 'Projectbeheerder', id: 3},
  {title: 'Organisatiebeheerder', id: 4},
];

export const subscriptions = {
  standard: 'Standaard',
  pro: 'Pro',
  connect: 'Connect',
  pro_connect: 'Pro & Connect',
};
