//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    this.$root.$reviewModal = this.$refs.reviewModal;
  },
};
