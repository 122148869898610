import {page as pageQuery} from '~/graphql/queries/page';

export const state = () => ({
  release: process.env.RELEASE,
  page: {seo: {}, template: {}},
  first_visit: null,
  force_show_review: false,
});

export const actions = {
  nuxtServerInit() {
    // Set a cookie so the timer in Review.vue knows when to show
    this.$cookiz.set('first_visit', (new Date()).getTime());
  },

  async getPage({commit}, slug = 'home') {
    const {data: {page}} = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        slug: slug === '' ? 'home' : slug,
      },
    });

    if (!page) {
      const e = new Error();
      e.statusCode = 404;
      throw e;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_FIRST_VISIT(state, payload) {
    state.first_visit = payload;
  },
};
