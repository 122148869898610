//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import categories from '~/graphql/queries/categories';
import {OPEN_SECTOR_CODES} from '~/constants/constants';

export default {
  apollo: {
    categories: {
      query: categories,
      update: ({categories}) => {
        return categories.filter(c => c.parent_id === null);
      },
    },
  },
  data: () => ({
    categories: [],
    openSub: null,
    openCode: null,
    label: {
      left: 0,
      right: 0,
      activeElem: null,
    },
    active: false,
  }),
  computed: {
    categoriesForActiveSector() {
      if (this.openCode) {
        const key = this.openCode.toLowerCase();
        return this.categories.filter(c => c[key] !== null).sort((a, b) => {
          return a[key] - b[key];
        });
      }
      return [];
    },
  },
  watch: {
    active(val) {
      if (val) {
        setTimeout(() => {
          this.changeActiveSector('stabu');
        }, 1);
      }
    },
  },
  created() {
    this.$nuxt.$on(OPEN_SECTOR_CODES, () => {
      this.active = true;
    });
  },
  mounted() {
    // TODO: This will break when uncommenting because the sectorcodes prop is no more!
    // this.setActiveSector(this.$refs.label0[0]);
    // this.sectorcodes.forEach((sectorcode, index) => {
    //   if (sectorcode.isActive) {
    //     this.openCode = index;
    //     sectorcode.subCodes.forEach((subcode, index2) => {
    //       if (subcode.isActive) {
    //         this.openSub = index + '-' + index2;
    //         setTimeout(() => {
    //           this.setActiveSector(this.label.activeElem);
    //         }, 350);
    //       }
    //     });
    //   }
    // });
  },
  methods: {
    async goToCategorySlug(slug) {
      await this.$router.push(`/materialen/${slug}`);
      this.active = false;
    },
    changeActiveSector(type) {
      const target = this.$refs[`code-${type}`];
      const labelClientRect = this.$refs.labelHolder.getBoundingClientRect();
      const targetClientRect = target.getBoundingClientRect();
      this.openCode = type;

      this.label = {
        left: targetClientRect.left - labelClientRect.left - 1,
        right: labelClientRect.right - targetClientRect.right - 1,
        activeElem: target,
      };
    },
    setActiveSector(elem) {
      this.label.left = elem.getBoundingClientRect().left - this.$refs.labelHolder.getBoundingClientRect().left - 1;
      this.label.right = this.$refs.labelHolder.getBoundingClientRect().right - elem.getBoundingClientRect().right - 1;
      this.label.activeElem = elem;
    },
    openItem(index, index2) {
      this.openSub = index + '-' + index2;
      setTimeout(() => {
        this.setActiveSector(this.label.activeElem);
      }, 350);
    },
    async navigateToCategory(category) {
      await this.$router.push(`/materialen/${category.slug}`);
    },
  },
}
;
