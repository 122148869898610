import {onError} from 'apollo-link-error';

export default function ({app}) {
  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      options.headers.authorization = app.$cookiz.get('auth._token.local') || app.$cookiz.get('auth._token.token');

      return fetch(uri, options);
    },
  };

  return {
    link: errorLink,
    httpLinkOptions: linkOptions,
    batching: true,
  };
}
