//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import organisationAccess from '~/plugins/mixins/organisationAccess';
import myOrganisations from '~/graphql/queries/dashboard/myOrganisations';
import subscriptions from '~/plugins/mixins/subscriptions';

export default {
  mixins: [organisationAccess, subscriptions],
  apollo: {
    myOrganisations: {
      query: myOrganisations,
      result({data}) {
        if (!data?.myOrganisations) {
          return;
        }

        this.myOrganisations = data.myOrganisations;

        if (this.mounted) {
          if (this.selectedOrganisation !== null) {
            this.thisSelectedOrganisation = this.selectedOrganisation;
          } else {
            this.thisSelectedOrganisation = this.myOrganisations[0]?.id;
            this.organisationChanged();
          }
        }
      },
    },
  },
  data: function () {
    return {
      openSidebar: true,
      thisSelectedOrganisation: null,
      myOrganisations: [],
      mounted: false,
    };
  },
  computed: {
    organisation() {
      return this.myOrganisations.length > 1 ? this.myOrganisations.filter(org => org.id === this.thisSelectedOrganisation)[0] : this.myOrganisations[0];
    },
    dashboardLinks() {
      return [
        {
          title: 'Reacties',
          link: '/dashboard',
          roles: [2, 3, 4, 5, 6, 7],
          icon: ['fal', 'tachometer-alt'],
          disabled: !this.isProjectManager,
        },
        {
          title: 'Profiel',
          link: '/dashboard/profiel',
          roles: [1, 2, 3, 4, 5, 6, 7],
          icon: ['fal', 'user'],
        },
        {
          title: 'Projecten',
          link: '/dashboard/projecten',
          roles: [2, 3, 4, 5, 6],
          icon: ['fal', 'clipboard'],
          disabled: !this.myOrganisations.length || !this.isMaterialManager,
        },
        {
          title: 'Materialen',
          link: '/dashboard/materialen',
          roles: [2, 3, 4, 5, 6],
          icon: ['fal', 'box'],
          disabled: !this.myOrganisations.length,
        },
        {
          title: 'Gebruikers',
          link: '/dashboard/gebruikers',
          roles: [2, 3, 4, 5, 6],
          icon: ['fal', 'users'],
          disabled: !this.isProjectManager,
        },
        {
          title: 'Favorieten',
          link: '/dashboard/favorieten',
          roles: [1, 2, 3, 4, 5, 6, 7],
          icon: ['fal', 'heart'],
        },
        {
          title: 'Zoekopdrachten',
          link: '/dashboard/zoekopdrachten',
          roles: [1, 2, 3, 4, 5, 6, 7],
          icon: ['fal', 'search-plus'],
        },
        {
          title: 'Widget',
          link: '/dashboard/widget',
          roles: [2, 3, 4, 5, 6],
          icon: ['fal', 'qrcode'],
          disabled: !(this.isProSubscription || this.isProConnectSubscription) || !this.isAdmin,
          greyOut: true,
        },
        {
          title: 'Importeren',
          link: '/dashboard/import',
          roles: [2, 3, 4, 5, 6],
          icon: ['fal', 'file-import'],
          // eslint-disable-next-line camelcase
          disabled: !this.activeOrganisation?.arc_gis_import || !this.isProOrBetterSubscription || !this.isAdmin,
          greyOut: true,
        },
      ];
    },
  },
  mounted() {
    this.mounted = true;
    this.sidebarLogic();
  },
  methods: {
    organisationChanged() {
      this.$store.commit('dashboard/setSelectedOrganisation', this.thisSelectedOrganisation);
      this.$router.push({name: this.isProjectManager ? 'dashboard' : 'dashboard-profiel'});
    },
    sidebarLogic() {
      this.openSidebar = window.innerWidth > 769;
      window.addEventListener('resize', () => {
        this.openSidebar = window.innerWidth > 769;
      });
    },
    closeSidebar() {
      if (window.innerWidth < 1024) {
        this.openSidebar = !this.openSidebar;
      }
    },
  },
};
