import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e07c624a&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/AppHeader.vue').default,CookieStatement: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/CookieStatement.vue').default,SearchSectorCode: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/SearchSectorCode.vue').default})
