import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _69c6344c = () => interopDefault(import('../pages/aanbieders/index.vue' /* webpackChunkName: "pages/aanbieders/index" */))
const _2e0cf642 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _e9732cb4 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _00ffb4d9 = () => interopDefault(import('../pages/inloggen/index.vue' /* webpackChunkName: "pages/inloggen/index" */))
const _1d293bf8 = () => interopDefault(import('../pages/materialen/index.vue' /* webpackChunkName: "pages/materialen/index" */))
const _816ace78 = () => interopDefault(import('../pages/offerte-aanvragen.vue' /* webpackChunkName: "pages/offerte-aanvragen" */))
const _72d4b47d = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _61d89eda = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _74f89a86 = () => interopDefault(import('../pages/dashboard/favorieten/index.vue' /* webpackChunkName: "pages/dashboard/favorieten/index" */))
const _6c93bce0 = () => interopDefault(import('../pages/dashboard/gebruikers/index.vue' /* webpackChunkName: "pages/dashboard/gebruikers/index" */))
const _2f531a36 = () => interopDefault(import('../pages/dashboard/import.vue' /* webpackChunkName: "pages/dashboard/import" */))
const _1f0fac53 = () => interopDefault(import('../pages/dashboard/materialen/index.vue' /* webpackChunkName: "pages/dashboard/materialen/index" */))
const _66dd9472 = () => interopDefault(import('../pages/dashboard/profiel.vue' /* webpackChunkName: "pages/dashboard/profiel" */))
const _6bb247f5 = () => interopDefault(import('../pages/dashboard/projecten/index.vue' /* webpackChunkName: "pages/dashboard/projecten/index" */))
const _5759f204 = () => interopDefault(import('../pages/dashboard/widget.vue' /* webpackChunkName: "pages/dashboard/widget" */))
const _6f938ee1 = () => interopDefault(import('../pages/dashboard/zoekopdrachten.vue' /* webpackChunkName: "pages/dashboard/zoekopdrachten" */))
const _2b43abc8 = () => interopDefault(import('../pages/oauth/authorize.vue' /* webpackChunkName: "pages/oauth/authorize" */))
const _5aefb2af = () => interopDefault(import('../pages/dashboard/gebruikers/add.vue' /* webpackChunkName: "pages/dashboard/gebruikers/add" */))
const _4d25a4ad = () => interopDefault(import('../pages/dashboard/materialen/nieuw.vue' /* webpackChunkName: "pages/dashboard/materialen/nieuw" */))
const _cc6f7f62 = () => interopDefault(import('../pages/dashboard/projecten/nieuw.vue' /* webpackChunkName: "pages/dashboard/projecten/nieuw" */))
const _1d070e51 = () => interopDefault(import('../pages/dashboard/favorieten/_uuid/index.vue' /* webpackChunkName: "pages/dashboard/favorieten/_uuid/index" */))
const _02131ca8 = () => interopDefault(import('../pages/dashboard/gebruikers/_uuid.vue' /* webpackChunkName: "pages/dashboard/gebruikers/_uuid" */))
const _1d590b0b = () => interopDefault(import('../pages/dashboard/materialen/_slug.vue' /* webpackChunkName: "pages/dashboard/materialen/_slug" */))
const _69fba6ad = () => interopDefault(import('../pages/dashboard/projecten/_slug.vue' /* webpackChunkName: "pages/dashboard/projecten/_slug" */))
const _9773ea50 = () => interopDefault(import('../pages/dashboard/toegang/_uuid.vue' /* webpackChunkName: "pages/dashboard/toegang/_uuid" */))
const _2250e6e6 = () => interopDefault(import('../pages/inloggen/redirect/_provider.vue' /* webpackChunkName: "pages/inloggen/redirect/_provider" */))
const _5d983dec = () => interopDefault(import('../pages/notificaties/unsubscribe/_uuid.vue' /* webpackChunkName: "pages/notificaties/unsubscribe/_uuid" */))
const _6d3376dc = () => interopDefault(import('../pages/aanbieders/_slug.vue' /* webpackChunkName: "pages/aanbieders/_slug" */))
const _6440dbff = () => interopDefault(import('../pages/project/_slug.vue' /* webpackChunkName: "pages/project/_slug" */))
const _0e8a14a2 = () => interopDefault(import('../pages/sectoren/_slug.vue' /* webpackChunkName: "pages/sectoren/_slug" */))
const _fa7ecb96 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _cdac0cee = () => interopDefault(import('../pages/wachtwoord-vergeten/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/_token" */))
const _1ddd0d14 = () => interopDefault(import('../pages/widget/_id.vue' /* webpackChunkName: "pages/widget/_id" */))
const _67f99e50 = () => interopDefault(import('../pages/materialen/*.vue' /* webpackChunkName: "pages/materialen/*" */))
const _62b0bbbd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanbieders",
    component: _69c6344c,
    pathToRegexpOptions: {"strict":true},
    name: "aanbieders"
  }, {
    path: "/dashboard",
    component: _2e0cf642,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/help",
    component: _e9732cb4,
    pathToRegexpOptions: {"strict":true},
    name: "help"
  }, {
    path: "/inloggen",
    component: _00ffb4d9,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen"
  }, {
    path: "/materialen",
    component: _1d293bf8,
    pathToRegexpOptions: {"strict":true},
    name: "materialen"
  }, {
    path: "/offerte-aanvragen",
    component: _816ace78,
    pathToRegexpOptions: {"strict":true},
    name: "offerte-aanvragen"
  }, {
    path: "/registreren",
    component: _72d4b47d,
    pathToRegexpOptions: {"strict":true},
    name: "registreren"
  }, {
    path: "/wachtwoord-vergeten",
    component: _61d89eda,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-vergeten"
  }, {
    path: "/dashboard/favorieten",
    component: _74f89a86,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-favorieten"
  }, {
    path: "/dashboard/gebruikers",
    component: _6c93bce0,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-gebruikers"
  }, {
    path: "/dashboard/import",
    component: _2f531a36,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-import"
  }, {
    path: "/dashboard/materialen",
    component: _1f0fac53,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-materialen"
  }, {
    path: "/dashboard/profiel",
    component: _66dd9472,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-profiel"
  }, {
    path: "/dashboard/projecten",
    component: _6bb247f5,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-projecten"
  }, {
    path: "/dashboard/widget",
    component: _5759f204,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-widget"
  }, {
    path: "/dashboard/zoekopdrachten",
    component: _6f938ee1,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-zoekopdrachten"
  }, {
    path: "/oauth/authorize",
    component: _2b43abc8,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-authorize"
  }, {
    path: "/dashboard/gebruikers/add",
    component: _5aefb2af,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-gebruikers-add"
  }, {
    path: "/dashboard/materialen/nieuw",
    component: _4d25a4ad,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-materialen-nieuw"
  }, {
    path: "/dashboard/projecten/nieuw",
    component: _cc6f7f62,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-projecten-nieuw"
  }, {
    path: "/dashboard/favorieten/:uuid",
    component: _1d070e51,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-favorieten-uuid"
  }, {
    path: "/dashboard/gebruikers/:uuid",
    component: _02131ca8,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-gebruikers-uuid"
  }, {
    path: "/dashboard/materialen/:slug",
    component: _1d590b0b,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-materialen-slug"
  }, {
    path: "/dashboard/projecten/:slug",
    component: _69fba6ad,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-projecten-slug"
  }, {
    path: "/dashboard/toegang/:uuid?",
    component: _9773ea50,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-toegang-uuid"
  }, {
    path: "/inloggen/redirect/:provider?",
    component: _2250e6e6,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-redirect-provider"
  }, {
    path: "/notificaties/unsubscribe/:uuid?",
    component: _5d983dec,
    pathToRegexpOptions: {"strict":true},
    name: "notificaties-unsubscribe-uuid"
  }, {
    path: "/aanbieders/:slug",
    component: _6d3376dc,
    pathToRegexpOptions: {"strict":true},
    name: "aanbieders-slug"
  }, {
    path: "/project/:slug?",
    component: _6440dbff,
    pathToRegexpOptions: {"strict":true},
    name: "project-slug"
  }, {
    path: "/sectoren/:slug?",
    component: _0e8a14a2,
    pathToRegexpOptions: {"strict":true},
    name: "sectoren-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _fa7ecb96,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-instellen-token"
  }, {
    path: "/wachtwoord-vergeten/:token?",
    component: _cdac0cee,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-vergeten-token"
  }, {
    path: "/widget/:id?",
    component: _1ddd0d14,
    pathToRegexpOptions: {"strict":true},
    name: "widget-id"
  }, {
    path: "/materialen/*",
    component: _67f99e50,
    pathToRegexpOptions: {"strict":true},
    name: "materialen-*"
  }, {
    path: "/",
    component: _62b0bbbd,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
