import Vue from 'vue';
import MediumEditor from 'medium-editor';
import MediumEditorEventBusListener from '~/plugins/medium-editor-event-bus-listener';

const me = new function () {
  this.init = (element) => {
    this.me = new MediumEditor(element, {
      toolbar: false,
      placeholder: {
        text: 'Voer een duidelijke omschrijving in.',
      },
      extensions: {
        'eventbus-listener': new MediumEditorEventBusListener(),
      },
      autoLink: true,
    });

    return this.me;
  };
}();

Vue.prototype.$mediumEditor = me;
