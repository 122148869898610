import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=6d96333e&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=js&"
export * from "./SearchBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=6d96333e&scoped=true&lang=css&"
import style1 from "./SearchBar.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d96333e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormSearchBarFieldWrapper: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/form/SearchBarFieldWrapper.vue').default,SectorCodes: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/SectorCodes.vue').default})
