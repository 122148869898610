const middleware = {}

middleware['destination'] = require('../middleware/destination.js')
middleware['destination'] = middleware['destination'].default || middleware['destination']

middleware['fixed-auth'] = require('../middleware/fixed-auth.js')
middleware['fixed-auth'] = middleware['fixed-auth'].default || middleware['fixed-auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
