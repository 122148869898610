import MediumEditor from 'medium-editor';
import EventBus from './eventbus';

const listeners = [];

const MediumEditorEventBusListener = MediumEditor.Extension.extend({
  name: 'eventbus-listener',
  init() {
    if (this.base.elements[0]) {
      this.subscribe('editableBlur', this.onBlur.bind(this));
      this.subscribe('focus', this.onFocus.bind(this));
      const id = Math.random().toString(36).substr(2, 9);

      listeners.push({
        id,
        active: false,
        lastActive: +new Date(),
      });

      EventBus.$on('mediumCommand', (type) => {
        const listenerElement = listeners.find(listener => listener.id === id);
        const currentTs = +new Date();
        if (listenerElement && (listenerElement.active || (currentTs - parseInt(listenerElement.lastActive) < 1500))) {
          this.execAction(type);
          listenerElement.lastActive = currentTs;
        }
      });
      this.base.elements[0].setAttribute('medium-eventbus-id', id);
    } else {
      this.log('Couldn\'t init buttons. Base MediumEditor not found in dom.', true);
    }
  },
  onBlur(event) {
    if (event.relatedTarget && event.relatedTarget.classList.contains('medium-button')) {
    } else if (event.target) {
      const id = event.target.getAttribute('medium-eventbus-id');
      if (id) {
        this.changeActiveStatus(id, false);
      }
    }
  },
  onFocus(event) {
    if (event.target) {
      const id = event.target.getAttribute('medium-eventbus-id');
      if (id) {
        this.changeActiveStatus(id, true);
      }
    }
  },
  changeActiveStatus(id, status) {
    const listenerElement = listeners.find(listener => listener.id === id);
    if (listenerElement) {
      listenerElement.active = status;
      listenerElement.lastActive = +new Date();
    }
  },
  log(message, error = false) {
    const messageString = '[EventBus Buttons] ' + message;
    if (!error) {
      console.log(messageString);
    } else {
      console.error(messageString);
    }
  },
});

export default MediumEditorEventBusListener;
