export default ({$axios, $toast, app, store}) => {
  $axios.onError((error) => {
    if (error.response) {
      const report = (e) => {
        if (app.$bugsnag) {
          app.$bugsnag.notify(e);
        }
      };

      // If $toast is not available (in SSR), create a dummy object
      if (!$toast) {
        $toast = {
          error: () => {},
        };
      }

      switch (error.response.status) {
        case 428:
        case 304:
          // Not modified
          break;

        case 401:
          $toast.error(error.response.data.message || 'U bent niet meer ingelogd. Vernieuw de pagina om door te gaan.');
          break;

        case 403:
          $toast.error(error.response.data.error || 'U heeft niet de juiste rechten om deze actie uit te voeren.');
          report(error);
          break;

        case 422:
          store.dispatch('validation/setErrors', error.response.data.errors);
          $toast.error('Niet alle velden zijn correct ingevuld.');
          break;

        default:
          $toast.error('Er is een fout opgetreden.');
          report(error);
      }
    }

    return Promise.reject(error);
  });

  $axios.onRequest(() => {
    store.dispatch('validation/clearErrors');
  });
};
