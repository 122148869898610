import { render, staticRenderFns } from "./withSidebar.vue?vue&type=template&id=028712ae&"
import script from "./withSidebar.vue?vue&type=script&lang=js&"
export * from "./withSidebar.vue?vue&type=script&lang=js&"
import style0 from "./withSidebar.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/AppHeader.vue').default,SidebarDashboard: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/SidebarDashboard.vue').default,Sidebar: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/Sidebar.vue').default,CookieStatement: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/CookieStatement.vue').default,SearchSectorCode: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/SearchSectorCode.vue').default,Review: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/review/Review.vue').default})
