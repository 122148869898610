import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=12e42f31&"
import script from "./Review.vue?vue&type=script&lang=js&"
export * from "./Review.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/general/Modal.vue').default})
