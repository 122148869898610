//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import organisationAccess from '~/plugins/mixins/organisationAccess';

export default {
  mixins: [organisationAccess],
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSearch: false,
    };
  },
  computed: {
    mobileMenu() {
      return [
        {
          title: 'Home',
          link: '/',
          icon: ['fal', 'home'],
          always: true,
        },
        {
          title: 'Over',
          link: 'https://insert.nl',
          externalLink: true,
          img: require('~/assets/img/icon.png'),
          always: true,
        },
        {
          title: 'Aanbieder',
          link: '/aanbieders',
          icon: ['far', 'fingerprint'],
          always: true,
        },
        {
          title: 'Plaatsen',
          link: '/dashboard/materialen/nieuw',
          icon: ['fal', 'plus'],
          always: true,
          disabled: !this.isMaterialManager,
        },
        {
          title: 'Inloggen',
          link: '/inloggen',
          icon: ['fal', 'sign-in-alt'],
          loggedIn: false,
        },
        {
          title: 'Uitloggen',
          link: '',
          icon: ['fal', 'sign-in-alt'],
          loggedIn: true,
        },
      ];
    },
    menuItems() {
      return this.mobileMenu.filter(item => (item.loggedIn === this.$store.state.auth.loggedIn || item.always) && !item.disabled);
    },
  },
  created() {
    this.showSearch = ['index', 'materialen'].includes(this.$route.name);
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.$store.commit('dashboard/setSelectedOrganisation', null);
    },
  },
};
