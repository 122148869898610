//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import searchOptions from '~/graphql/queries/searchOptions';

export default {
  components: {
    'v-select': vSelect,
  },
  apollo: {
    searchOptions: {
      query: searchOptions,
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slideOpen: this.show,
      searchOptions: [],
      form: {
        query: this.$route.query && this.$route.query.query,
        distance: null,
      },
      distances: [
        {value: '*', title: 'Alle afstanden'},
        {value: 5, title: '5 km'},
        {value: 10, title: '10 km'},
        {value: 20, title: '20 km'},
        {value: 50, title: '50 km'},
        {value: 75, title: '75 km'},
        {value: 100, title: '100 km'},
      ],
    };
  },
  computed: {
    categories() {
      const categories = this.searchOptions.find(option => option.title === 'categories');
      if (!categories) {
        return [];
      }
      return categories.values;
    },
    sectors() {
      const sectors = this.searchOptions.find(option => option.title === 'sectoren');
      if (!sectors) {
        return [];
      }
      return sectors.values;
    },
  },
  methods: {
    postalCodeUpdated() {
      if (this.form.postal_code && !this.form.distance) {
        this.form.distance = 5;
      }
    },
    submit() {
      this.$router.push({name: 'materialen', query: {...this.form}});
    },
  },
};
