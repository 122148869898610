//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import {requestAccessToMaterialOrProject} from '~/graphql/mutations/requestAccessToMaterialOrProject';
import {checkAccessRequest} from '~/graphql/queries/checkAccessRequest';

export default {
  layout: 'fullWidthCollapsed',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    checkAccessRequest: {
      query: checkAccessRequest,
      variables() {
        return {
          type: this.error.type,
          slug: this.error.slug,
        };
      },
      skip() {
        return !this.$store.state.auth.loggedIn || !this.error.type || !this.error.slug;
      },
    },
  },
  data: () => ({
    checkAccessRequest: false,
  }),
  computed: mapState(['page']),
  async created() {
    if (this.error.statusCode === 403 && !this.$store.state.auth.loggedIn) {
      return this.$router.push({name: 'inloggen', query: {destination: this.$route.fullPath}});
    }
    await this.$store.dispatch('getPage', '404');
  },
  mounted() {
    console.log(`[${this.error.statusCode || 0}] ${this.error.message}`);
    console.log(this.error);
  },
  methods: {
    async requestAccess() {
      try {
        await this.$apollo.mutate({
          mutation: requestAccessToMaterialOrProject,
          variables: {
            type: this.error.type,
            slug: this.error.slug,
          },
          refetchQueries: ['checkAccessRequest'],
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  head() {
    let title = this.page.seo ? this.page.seo.meta_title : this.page.titlel;

    if (this.error.statusCode === 403) {
      title = 'Geen toegang';
    }

    return {
      title,
    };
  },
};

