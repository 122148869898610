export const state = () => ({
  selectedOrganisation: null,
});

export const getters = {
  getSelectedOrganisation: (state) => {
    return state.selectedOrganisation;
  },
};

export const mutations = {
  setSelectedOrganisation(state, payload) {
    state.selectedOrganisation = payload;
  },
};
