//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mainCategories from '~/graphql/queries/mainCategories';
import sectoren from '~/graphql/queries/sectoren';

export default {
  apollo: {
    categories: {
      query: mainCategories,
      update(data) {
        return data.mainCategories;
      },
    },
    sectoren: {
      query: sectoren,
    },
  },
  data: () => ({
    openSidebar: null,
    showAllCategories: false,
    categories: [],
  }),
  mounted() {
    if (this.$device.isMobileOrTablet) {
      return this.openSidebar = false;
    } else {
      this.openSidebar = true;
    }
  },
  // methods: {
  //   sidebarLogic() {
  //     this.openSidebar = window.innerWidth > 769;
  //     window.addEventListener('resize', () => {
  //       this.openSidebar = window.innerWidth > 769;
  //     });
  //   },
  // },
};
