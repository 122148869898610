import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getSelectedOrganisation: 'dashboard/getSelectedOrganisation',
    }),
    authUser() {
      return this.$store.state?.auth?.user || false;
    },
    selectedOrganisation() {
      return this.getSelectedOrganisation;
    },
    isAdmin() {
      return this.authUser ? this.authUser.organisations_admin_ids?.includes(this.selectedOrganisation) : false;
    },
    isProjectManager() {
      return this.authUser ? (this.isAdmin || this.authUser.project_manager_organisation_ids?.includes(this.selectedOrganisation)) : false;
    },
    isMaterialManager() {
      return this.authUser ? (this.isAdmin || this.authUser.material_manager_organisation_ids?.includes(this.selectedOrganisation)) : false;
    },
  },
};
