import Vue from 'vue';
import GMap from "\u002Fdata\u002Fwww\u002Fmarktplaats.insert.nl\u002Finsert\u002Fnuxt\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMap.vue";
import GMapMarker from "\u002Fdata\u002Fwww\u002Fmarktplaats.insert.nl\u002Finsert\u002Fnuxt\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapMarker.vue";
import GMapCircle from "\u002Fdata\u002Fwww\u002Fmarktplaats.insert.nl\u002Finsert\u002Fnuxt\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapCircle.vue";
import GMapInfoWindow from "\u002Fdata\u002Fwww\u002Fmarktplaats.insert.nl\u002Finsert\u002Fnuxt\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapInfoWindow.vue";
export default(context, inject) =>{
  Vue.component('GMap', GMap);
  Vue.component('GMapMarker', GMapMarker);
  Vue.component('GMapCircle', GMapCircle);
  Vue.component('GMapInfoWindow', GMapInfoWindow);
  inject('GMaps', {apiKey: "AIzaSyDidh9_TifsY61NYB_anbPyja-uN8ELxUs", loaded: false, libraries: undefined})
}