import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_1537c8ab from 'nuxt_plugin_plugin_1537c8ab' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_nuxtcookiecontrol_65f84cb9 from 'nuxt_plugin_nuxtcookiecontrol_65f84cb9' // Source: ./nuxt-cookie-control.js (mode: 'all')
import nuxt_plugin_nuxtgooglemaps_2c89d414 from 'nuxt_plugin_nuxtgooglemaps_2c89d414' // Source: ./nuxt-google-maps.js (mode: 'all')
import nuxt_plugin_gtm_93d38152 from 'nuxt_plugin_gtm_93d38152' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_libplugin6cfef347_ae2c5eee from 'nuxt_plugin_libplugin6cfef347_ae2c5eee' // Source: ./lib.plugin.6cfef347.js (mode: 'all')
import nuxt_plugin_toast_926cae20 from 'nuxt_plugin_toast_926cae20' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_workbox_2b83c51d from 'nuxt_plugin_workbox_2b83c51d' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_2f089f30 from 'nuxt_plugin_metaplugin_2f089f30' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_33b044a4 from 'nuxt_plugin_iconplugin_33b044a4' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_2acd477e from 'nuxt_plugin_axios_2acd477e' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtapollomodule_2b919797 from 'nuxt_plugin_nuxtapollomodule_2b919797' // Source: ./nuxt-apollo-module.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d7074c2e from 'nuxt_plugin_cookieuniversalnuxt_d7074c2e' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_moment_773dbd77 from 'nuxt_plugin_moment_773dbd77' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_axios_630a4306 from 'nuxt_plugin_axios_630a4306' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_bugsnag_4aea807c from 'nuxt_plugin_bugsnag_4aea807c' // Source: ../plugins/bugsnag (mode: 'all')
import nuxt_plugin_fontawesome_5e0b9dfb from 'nuxt_plugin_fontawesome_5e0b9dfb' // Source: ../plugins/fontawesome (mode: 'all')
import nuxt_plugin_directives_f60b4746 from 'nuxt_plugin_directives_f60b4746' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_validation_b46593c6 from 'nuxt_plugin_validation_b46593c6' // Source: ../plugins/mixins/validation (mode: 'all')
import nuxt_plugin_lazysizesclient_1d6163c3 from 'nuxt_plugin_lazysizesclient_1d6163c3' // Source: ../plugins/lazysizes.client.js (mode: 'client')
import nuxt_plugin_swiper_7aeed508 from 'nuxt_plugin_swiper_7aeed508' // Source: ../plugins/swiper.client (mode: 'client')
import nuxt_plugin_vuexpersist_0be8ce67 from 'nuxt_plugin_vuexpersist_0be8ce67' // Source: ../plugins/vuex-persist.client (mode: 'client')
import nuxt_plugin_mediumeditor_021dc22d from 'nuxt_plugin_mediumeditor_021dc22d' // Source: ../plugins/medium-editor.client (mode: 'client')
import nuxt_plugin_filter_08973a51 from 'nuxt_plugin_filter_08973a51' // Source: ../plugins/filter (mode: 'all')
import nuxt_plugin_tooltip_0d69876c from 'nuxt_plugin_tooltip_0d69876c' // Source: ../plugins/tooltip (mode: 'all')
import nuxt_plugin_vshowslide_c6f3ee9e from 'nuxt_plugin_vshowslide_c6f3ee9e' // Source: ../plugins/v-show-slide (mode: 'all')
import nuxt_plugin_clickoutside_44269d32 from 'nuxt_plugin_clickoutside_44269d32' // Source: ../plugins/click-outside (mode: 'all')
import nuxt_plugin_plugin_883f1686 from 'nuxt_plugin_plugin_883f1686' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Insert","titleTemplate":(t) => {
      return t + (t === 'Insert' ? '' : ' – Insert');
    },"htmlAttrs":{"lang":"nl"},"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","hid":"description","content":""},{"name":"apple-mobile-web-app-title","hid":"apple-mobile-web-app-title","content":"Insert"},{"name":"og:title","hid":"og:title","content":"Insert"},{"name":"og:description","hid":"og:description","content":""},{"name":"og:site_name","hid":"og:site_name","content":"Insert"}],"link":[{"rel":"stylesheet","href":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Ffont-awesome\u002F5.9.0\u002Fcss\u002Fsvg-with-js.min.css"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-K7XWS6V&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_1537c8ab === 'function') {
    await nuxt_plugin_plugin_1537c8ab(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtcookiecontrol_65f84cb9 === 'function') {
    await nuxt_plugin_nuxtcookiecontrol_65f84cb9(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtgooglemaps_2c89d414 === 'function') {
    await nuxt_plugin_nuxtgooglemaps_2c89d414(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_93d38152 === 'function') {
    await nuxt_plugin_gtm_93d38152(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin6cfef347_ae2c5eee === 'function') {
    await nuxt_plugin_libplugin6cfef347_ae2c5eee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_926cae20 === 'function') {
    await nuxt_plugin_toast_926cae20(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_2b83c51d === 'function') {
    await nuxt_plugin_workbox_2b83c51d(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_2f089f30 === 'function') {
    await nuxt_plugin_metaplugin_2f089f30(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_33b044a4 === 'function') {
    await nuxt_plugin_iconplugin_33b044a4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2acd477e === 'function') {
    await nuxt_plugin_axios_2acd477e(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtapollomodule_2b919797 === 'function') {
    await nuxt_plugin_nuxtapollomodule_2b919797(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d7074c2e === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d7074c2e(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_773dbd77 === 'function') {
    await nuxt_plugin_moment_773dbd77(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_630a4306 === 'function') {
    await nuxt_plugin_axios_630a4306(app.context, inject)
  }

  if (typeof nuxt_plugin_bugsnag_4aea807c === 'function') {
    await nuxt_plugin_bugsnag_4aea807c(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_5e0b9dfb === 'function') {
    await nuxt_plugin_fontawesome_5e0b9dfb(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_f60b4746 === 'function') {
    await nuxt_plugin_directives_f60b4746(app.context, inject)
  }

  if (typeof nuxt_plugin_validation_b46593c6 === 'function') {
    await nuxt_plugin_validation_b46593c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazysizesclient_1d6163c3 === 'function') {
    await nuxt_plugin_lazysizesclient_1d6163c3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_swiper_7aeed508 === 'function') {
    await nuxt_plugin_swiper_7aeed508(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersist_0be8ce67 === 'function') {
    await nuxt_plugin_vuexpersist_0be8ce67(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mediumeditor_021dc22d === 'function') {
    await nuxt_plugin_mediumeditor_021dc22d(app.context, inject)
  }

  if (typeof nuxt_plugin_filter_08973a51 === 'function') {
    await nuxt_plugin_filter_08973a51(app.context, inject)
  }

  if (typeof nuxt_plugin_tooltip_0d69876c === 'function') {
    await nuxt_plugin_tooltip_0d69876c(app.context, inject)
  }

  if (typeof nuxt_plugin_vshowslide_c6f3ee9e === 'function') {
    await nuxt_plugin_vshowslide_c6f3ee9e(app.context, inject)
  }

  if (typeof nuxt_plugin_clickoutside_44269d32 === 'function') {
    await nuxt_plugin_clickoutside_44269d32(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_883f1686 === 'function') {
    await nuxt_plugin_plugin_883f1686(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
