export const Breadcrumbs = () => import('../../components/general/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BulkDelete = () => import('../../components/general/BulkDelete.vue' /* webpackChunkName: "components/bulk-delete" */).then(c => wrapFunctional(c.default || c))
export const BulkInterest = () => import('../../components/general/BulkInterest.vue' /* webpackChunkName: "components/bulk-interest" */).then(c => wrapFunctional(c.default || c))
export const CardPlaceholder = () => import('../../components/general/CardPlaceholder.vue' /* webpackChunkName: "components/card-placeholder" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/general/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const ImgGrid = () => import('../../components/general/ImgGrid.vue' /* webpackChunkName: "components/img-grid" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/general/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NewFavoritesList = () => import('../../components/general/NewFavoritesList.vue' /* webpackChunkName: "components/new-favorites-list" */).then(c => wrapFunctional(c.default || c))
export const OrganisatiesList = () => import('../../components/general/OrganisatiesList.vue' /* webpackChunkName: "components/organisaties-list" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/general/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVertical = () => import('../../components/general/ProductCardVertical.vue' /* webpackChunkName: "components/product-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const ProductCardWidget = () => import('../../components/general/ProductCardWidget.vue' /* webpackChunkName: "components/product-card-widget" */).then(c => wrapFunctional(c.default || c))
export const SaveFavorite = () => import('../../components/general/SaveFavorite.vue' /* webpackChunkName: "components/save-favorite" */).then(c => wrapFunctional(c.default || c))
export const SelectList = () => import('../../components/general/SelectList.vue' /* webpackChunkName: "components/select-list" */).then(c => wrapFunctional(c.default || c))
export const Separate = () => import('../../components/general/Separate.vue' /* webpackChunkName: "components/separate" */).then(c => wrapFunctional(c.default || c))
export const TextBlock = () => import('../../components/general/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => wrapFunctional(c.default || c))
export const FormButton = () => import('../../components/form/Button.vue' /* webpackChunkName: "components/form-button" */).then(c => wrapFunctional(c.default || c))
export const FormDynamicForm = () => import('../../components/form/DynamicForm.vue' /* webpackChunkName: "components/form-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const FormEventButtons = () => import('../../components/form/EventButtons.vue' /* webpackChunkName: "components/form-event-buttons" */).then(c => wrapFunctional(c.default || c))
export const FormSearchBarFieldWrapper = () => import('../../components/form/SearchBarFieldWrapper.vue' /* webpackChunkName: "components/form-search-bar-field-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FormStylableTextarea = () => import('../../components/form/StylableTextarea.vue' /* webpackChunkName: "components/form-stylable-textarea" */).then(c => wrapFunctional(c.default || c))
export const FormUploadFile = () => import('../../components/form/UploadFile.vue' /* webpackChunkName: "components/form-upload-file" */).then(c => wrapFunctional(c.default || c))
export const FormUploadPdf = () => import('../../components/form/UploadPdf.vue' /* webpackChunkName: "components/form-upload-pdf" */).then(c => wrapFunctional(c.default || c))
export const FormWysiwygEditor = () => import('../../components/form/WysiwygEditor.vue' /* webpackChunkName: "components/form-wysiwyg-editor" */).then(c => wrapFunctional(c.default || c))
export const FormFieldDate = () => import('../../components/form/field/Date.vue' /* webpackChunkName: "components/form-field-date" */).then(c => wrapFunctional(c.default || c))
export const FormFieldEmail = () => import('../../components/form/field/Email.vue' /* webpackChunkName: "components/form-field-email" */).then(c => wrapFunctional(c.default || c))
export const FormFieldInput = () => import('../../components/form/field/Input.vue' /* webpackChunkName: "components/form-field-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldNumber = () => import('../../components/form/field/Number.vue' /* webpackChunkName: "components/form-field-number" */).then(c => wrapFunctional(c.default || c))
export const FormFieldRadio = () => import('../../components/form/field/Radio.vue' /* webpackChunkName: "components/form-field-radio" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelect = () => import('../../components/form/field/Select.vue' /* webpackChunkName: "components/form-field-select" */).then(c => wrapFunctional(c.default || c))
export const FormFieldText = () => import('../../components/form/field/Text.vue' /* webpackChunkName: "components/form-field-text" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../components/Category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const CookieStatement = () => import('../../components/CookieStatement.vue' /* webpackChunkName: "components/cookie-statement" */).then(c => wrapFunctional(c.default || c))
export const FavoritesCta = () => import('../../components/FavoritesCta.vue' /* webpackChunkName: "components/favorites-cta" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const LinkSharing = () => import('../../components/LinkSharing.vue' /* webpackChunkName: "components/link-sharing" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Paginator = () => import('../../components/Paginator.vue' /* webpackChunkName: "components/paginator" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchSectorCode = () => import('../../components/SearchSectorCode.vue' /* webpackChunkName: "components/search-sector-code" */).then(c => wrapFunctional(c.default || c))
export const SectorCodes = () => import('../../components/SectorCodes.vue' /* webpackChunkName: "components/sector-codes" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarDashboard = () => import('../../components/SidebarDashboard.vue' /* webpackChunkName: "components/sidebar-dashboard" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardComment = () => import('../../components/dashboard/Comment.vue' /* webpackChunkName: "components/dashboard-comment" */).then(c => wrapFunctional(c.default || c))
export const DashboardFavoriteProduct = () => import('../../components/dashboard/FavoriteProduct.vue' /* webpackChunkName: "components/dashboard-favorite-product" */).then(c => wrapFunctional(c.default || c))
export const DashboardPasswordForm = () => import('../../components/dashboard/PasswordForm.vue' /* webpackChunkName: "components/dashboard-password-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardProduct = () => import('../../components/dashboard/Product.vue' /* webpackChunkName: "components/dashboard-product" */).then(c => wrapFunctional(c.default || c))
export const DashboardProductForm = () => import('../../components/dashboard/ProductForm.vue' /* webpackChunkName: "components/dashboard-product-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardProfileForm = () => import('../../components/dashboard/ProfileForm.vue' /* webpackChunkName: "components/dashboard-profile-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardProject = () => import('../../components/dashboard/Project.vue' /* webpackChunkName: "components/dashboard-project" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectForm = () => import('../../components/dashboard/ProjectForm.vue' /* webpackChunkName: "components/dashboard-project-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectFormHintModal = () => import('../../components/dashboard/ProjectFormHintModal.vue' /* webpackChunkName: "components/dashboard-project-form-hint-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectFormNotifyChangedInheritedValues = () => import('../../components/dashboard/ProjectFormNotifyChangedInheritedValues.vue' /* webpackChunkName: "components/dashboard-project-form-notify-changed-inherited-values" */).then(c => wrapFunctional(c.default || c))
export const DashboardUserChangeRole = () => import('../../components/dashboard/UserChangeRole.vue' /* webpackChunkName: "components/dashboard-user-change-role" */).then(c => wrapFunctional(c.default || c))
export const HomeIconCard = () => import('../../components/home/IconCard.vue' /* webpackChunkName: "components/home-icon-card" */).then(c => wrapFunctional(c.default || c))
export const HomeListOfProducts = () => import('../../components/home/ListOfProducts.vue' /* webpackChunkName: "components/home-list-of-products" */).then(c => wrapFunctional(c.default || c))
export const PdpGallery = () => import('../../components/pdp/Gallery.vue' /* webpackChunkName: "components/pdp-gallery" */).then(c => wrapFunctional(c.default || c))
export const PdpInterestedForm = () => import('../../components/pdp/InterestedForm.vue' /* webpackChunkName: "components/pdp-interested-form" */).then(c => wrapFunctional(c.default || c))
export const PdpProductSpecs = () => import('../../components/pdp/ProductSpecs.vue' /* webpackChunkName: "components/pdp-product-specs" */).then(c => wrapFunctional(c.default || c))
export const PdpShortSpecBar = () => import('../../components/pdp/ShortSpecBar.vue' /* webpackChunkName: "components/pdp-short-spec-bar" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../components/project/Card.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/review/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const TableCell = () => import('../../components/table/Cell.vue' /* webpackChunkName: "components/table-cell" */).then(c => wrapFunctional(c.default || c))
export const TableOverview = () => import('../../components/table/Overview.vue' /* webpackChunkName: "components/table-overview" */).then(c => wrapFunctional(c.default || c))
export const TabsTab = () => import('../../components/tabs/Tab.vue' /* webpackChunkName: "components/tabs-tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/tabs/index.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const UserCard = () => import('../../components/user/Card.vue' /* webpackChunkName: "components/user-card" */).then(c => wrapFunctional(c.default || c))
export const UserOverview = () => import('../../components/user/Overview.vue' /* webpackChunkName: "components/user-overview" */).then(c => wrapFunctional(c.default || c))
export const TableRowAction = () => import('../../components/table/row/Action.vue' /* webpackChunkName: "components/table-row-action" */).then(c => wrapFunctional(c.default || c))
export const TableRowControls = () => import('../../components/table/row/Controls.vue' /* webpackChunkName: "components/table-row-controls" */).then(c => wrapFunctional(c.default || c))
export const TableRow = () => import('../../components/table/row/index.vue' /* webpackChunkName: "components/table-row" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
