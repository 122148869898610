//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    edgeLine: {
      type: Boolean,
      default: false,
    },
  },
};
