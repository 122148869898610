import {mapGetters} from 'vuex';
import {subscriptions} from '~/plugins/mixins/constants';
export default {
  computed: {
    ...mapGetters({
      getSelectedOrganisation: 'dashboard/getSelectedOrganisation',
    }),
    activeOrganisation() {
      return this.$store.state.auth.user.organisaties.find(org => org.id === this.getSelectedOrganisation);
    },
    subcription() {
      return this.activeOrganisation?.abonnement;
    },
    isStandardSubscription() {
      return this.subcription === subscriptions.standard;
    },
    isProSubscription() {
      return this.subcription === subscriptions.pro;
    },
    isConnectSubscription() {
      return this.subcription === subscriptions.connect;
    },
    isProConnectSubscription() {
      return this.subcription === subscriptions.pro_connect;
    },
    isProOrBetterSubscription() {
      return this.isProSubscription || this.isConnectSubscription || this.isProConnectSubscription;
    },
  },
};
