//
//
//
//
//
//
//
//
//
//
//
//

import {OPEN_SECTOR_CODES} from '~/constants/constants';

export default {
  methods: {
    openSectorCodes() {
      this.$nuxt.$emit(OPEN_SECTOR_CODES);
    },
  },
};
