//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data: () => ({
    starsHovering: 0,
    starsSelected: 0,
    currentStep: 1,
    active: false,
    hasReachedTime: false,
    improvement: '',
  }),
  mounted() {
    setInterval(() => {
      const diff = this.$moment(this.$cookiz.get('first_visit')).diff(this.$moment(), 'minutes');

      if (diff <= -2 && !this.active && !this.$cookiz.get('has_reviewed')) {
        this.active = true;
      }
    }, 1000);
  },
  methods: {
    selectStars(index) {
      this.starsSelected = index;
      this.currentStep = 2;
    },
    async submitFeedback() {
      try {
        await this.$axios.post('forms/1', {
          'aantal-sterren_1': this.starsSelected,
          verbeterpunten_2: this.improvement || '-',
        });

        this.$toast.info('Bedankt voor de feedback!');
      } catch (e) {
        console.error(e);
        this.$toast.error('Er is iets mis gegaan tijdens het versturen van de beoordeling. Probeer het later nog eens.');
      } finally {
        this.closeDialog();
      }
    },
    open() {
      this.active = true;
    },
    closeDialog() {
      this.$cookiz.set('has_reviewed', true, {
        expires: this.$moment().add(1, 'month').toDate(),
      });
      this.active = false;
    },
  },
};
