import { render, staticRenderFns } from "./SidebarDashboard.vue?vue&type=template&id=7c72b512&"
import script from "./SidebarDashboard.vue?vue&type=script&lang=js&"
export * from "./SidebarDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Separate: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/general/Separate.vue').default})
