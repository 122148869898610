import Vue from 'vue';
import moment from 'moment';

Vue.filter('ucFirst', (val) => {
  if (!val) {
    return val;
  }
  return val.charAt(0).toUpperCase() + val.slice(1);
});

Vue.filter('dateFormat', (val, format = 'DD-MM-YYYY') => {
  if (!val) {
    return val;
  }
  return moment(val).format(format);
});
