import { render, staticRenderFns } from "./fullWidthCollapsed.vue?vue&type=template&id=0b0d3946&"
import script from "./fullWidthCollapsed.vue?vue&type=script&lang=js&"
export * from "./fullWidthCollapsed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/AppHeader.vue').default,CookieStatement: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/CookieStatement.vue').default,SearchSectorCode: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/SearchSectorCode.vue').default,Review: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/review/Review.vue').default})
