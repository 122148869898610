export const OPEN_SECTOR_CODES = 'open-sector-codes';

export const roles = [
  {label: 'Onbekend', value: 0},
  {label: 'Lid', value: 1},
  {label: 'Medewerker', value: 2},
  {label: 'Redacteur', value: 3},
  {label: 'Beheerder', value: 4},
  {label: 'Developer', value: 5},
];

export const AMSTERDAM_ORGANISATION_ID = 29;
export const BUITENRUIMTE_SECTOR_ID = 4;
