export const state = () => ({
  favorite_products: [],
});

export const getters = {
  hasFavorite: state => (id) => {
    return state.favorite_products.findIndex(p => p.id === id) !== -1;
  },
};

export const mutations = {
  removeFavorite(state, payload) {
    state.favorite_products = state.favorite_products.filter(product => product.id !== payload.id);
  },
  removeFavorites(state, ids) {
    state.favorite_products = state.favorite_products.filter(product => !ids.includes(product.id.toString()));
  },
  addFavorite(state, payload) {
    const exists = state.favorite_products.findIndex(f => f.id === payload.id);
    if (exists === -1) {
      state.favorite_products.push(payload);
    }
  },
};
