//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import organisationAccess from '~/plugins/mixins/organisationAccess';

export default {
  mixins: [organisationAccess],
  data() {
    return {
      query: this.$route.query && this.$route.query.query,
    };
  },
  computed: {
    mainRoute() {
      return this.$route.name;
    },
    isLoggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    loggedInRoute() {
      if (!this.isLoggedIn) {
        return null;
      }

      return this.isProjectManager ? 'dashboard' : 'dashboard-profiel';
    },
  },
  methods: {
    doSearch() {
      if (!this.query) {
        this.$refs.searchText.focus();

        return;
      }

      this.$router.push({name: 'materialen', query: {query: this.query}});
    },
    async logout() {
      await this.$apolloProvider.defaultClient.cache.reset();
      await this.$auth.logout();
      await this.$router.push({name: 'inloggen'});

      this.$store.commit('dashboard/setSelectedOrganisation', null);

      try {
        await this.$apolloProvider.defaultClient.cache.reset();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
