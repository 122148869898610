import VuexPersistence from 'vuex-persist';

export default ({store}) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      storage: window.localStorage,
      reducer: state => ({
        favorites: state.favorites,
        dashboard: state.dashboard,
      }),
    }).plugin(store);
  });
};
